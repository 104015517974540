import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import VectorImages from "../../images/vector";

const { arrow } = VectorImages;

const { Btn, Arrow } = Styles;

const Button = ({ children }: PropsWithChildren<{}>) => {
    return (
        <Btn to='/pl/#contactUs'>
            {children}
            <Arrow src={arrow} />
        </Btn>
    );
};

export default Button;
