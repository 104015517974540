import { v4 as uuidv4 } from "uuid";
import VectorImages from "../../../images/vector";

const { financeTick } = VectorImages;

const financeManagementCardsData = [
    {
        id: uuidv4(),
        image: financeTick,
        text: "Dostęp do raportów finansowych.",
    },
    {
        id: uuidv4(),
        image: financeTick,
        text: "Zarządzanie kosztami i przychodami.",
    },
    {
        id: uuidv4(),
        image: financeTick,
        text: "Wyniki finansowe, wykrywanie nieprawidłowości i audyty projektów.",
    },
];

export { financeManagementCardsData as financeManagementCardsData };
