import React from "react";
import Styles from "./styles";
import H2Title from "../../../components/H2Title";

const { Area } = Styles;

const Title = () => {
    return (
        <Area>
            <H2Title>Zarządzanie czasem pracy</H2Title>
        </Area>
    );
};

export default Title;
