import React from "react";
import { timeManagementCardsData } from "./data";
import Styles from "./styles";
import Card from "../Card";

const { Area } = Styles;

const Grid = () => {
    return (
        <Area>
            {timeManagementCardsData.map(({ id, title, image, text }) => (
                <Card key={id} props={{ id, image, title, text }}>
                    {text}
                </Card>
            ))}
        </Area>
    );
};

export default Grid;