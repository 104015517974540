import React from "react";
import Styles from "./styles";
import useLandingQuery from "./query";

const { Area, LandingImage } = Styles;

const LandingImg = () => {
    const gatsbyImageData = useLandingQuery();

    console.log(gatsbyImageData);
    return (
        <Area>
            <LandingImage image={gatsbyImageData} alt="landing video" />
        </Area>
    );
};

export default LandingImg;
