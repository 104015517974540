import styled from "styled-components";

const Styles = {
    Area: styled.div`
        grid-area: biDesc;
        position: relative;
        left:0px;
        /* padding-top: 300px; */
        margin-bottom: 200px;
    `,

    
};

export default Styles;
