import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { myTheme } from "../../../MyTheme";

const { imageShadow } = myTheme;

const Styles = {
    Area: styled.div`
        grid-area: docsManagementImg;
        padding: 0 10px 150px 10px;
        justify-content: center;
        align-items: center;
        @media (max-width: 900px) {
            padding: 0 10px 50px 10px;
        }
    `,
    LandingImage: styled(GatsbyImage)`
        width: 60%;
        filter: ${imageShadow}; 
        object-fit: scale-down;
        @media (max-width: 900px) {
            width: 100%;
        }
    `,
};

export default Styles;
