import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const Styles = {
    Area: styled.div`
        grid-area: biDesc;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    LandingImage: styled(GatsbyImage)`
        width: 50%;
        @media (max-width: 900px) {
            position: absolute;
            width: 25%;
            right: 0;
        }
    `,
};

export default Styles;
