import React from "react";
import Styles from "./styles";
import H2Title from "../../../components/H2Title";
import PText from "../../../components/PText";

const { Area} = Styles;

const Title = () => {
    return (
        <Area>
            <H2Title textAlign={"left"}>Zarządzanie kadrami</H2Title>
            <br/>
            <PText>
            Łatwo zarządzaj strukturą organizacji przypisując pracowników do działów. 
            </PText>
            <br/>
            <PText>
            Zarządzaj profilami pracowników z łatwym dostępem do dokumentów, danych kontaktowych jak i przypisanym wyposażeniem jak laptopy, telefony itd
            </PText>
            <br/>
            <PText>
            Łatwy dostęp do notatek dotyczących pracownika dla działu HR
            </PText>
        </Area>
    );
};

export default Title;
