import React, { PropsWithChildren } from "react";
import Styles from "./styles";
const { Wrapper } = Styles;
import { Mobile } from "./types";
import NavigationBtnProduct from "../../../components/NavigationBtnProduct";
import NavigationBtnContact from "../../../components/NavigationBtnContact";

const MobileMenu = ({ isMenuActive }: PropsWithChildren<Mobile>) => {
  return (
    <Wrapper isMenuActive={isMenuActive}>
      <NavigationBtnProduct mobile={true}>O produkcie</NavigationBtnProduct>
      <NavigationBtnContact mobile={true}>Kontakt</NavigationBtnContact>
    </Wrapper>
  );
};

export default MobileMenu;
