import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import PText from "../../../components/PText";
import { ICard } from "./types";

const { Wrapper, DescContainer, CardImg } = Styles;

const Card = ({
    props: { text, image },
}: PropsWithChildren<ICard>) => {
    return (
        <Wrapper>
            <CardImg src={image} />
            <DescContainer>
                <PText>{text}</PText>
            </DescContainer>
        </Wrapper>
    );
};

export default Card;
