import React from "react";
import Styles from "./styles";
import useProjectsViewImgQuery from "./query";

const { Area, LandingImage } = Styles;

const LandingImg = () => {
    const gatsbyImageData = useProjectsViewImgQuery();

    console.log(gatsbyImageData);
    return (
        <Area>
            <LandingImage image={gatsbyImageData} alt="hr"/>
        </Area>
    );
};

export default LandingImg;