import styled from "styled-components";

const Styles = {
    Wrapper: styled.div`
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `,
};

export default Styles;
