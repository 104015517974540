import React from "react";
import { footerData } from "./data";
import Styles from "./styles";
import FooterCard from "../FooterCard";

const { Area } = Styles;

const Grid = () => {
    return (
        <Area>
            {footerData.map(({ id, title, desc1, desc2 }) => (
                <FooterCard key={id} props={{ title, desc1, desc2 }}/>
            ))}
        </Area>
    );
};

export default Grid;