import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { myTheme } from "../../../MyTheme";

const { imageShadow } = myTheme;

const Styles = {
    Area: styled.div`
        grid-area: biImg;
        padding: 15px;
        margin-bottom: 200px;
    `,
    LandingImage: styled(GatsbyImage)`
        width: 60%;
        padding: 70px;
        filter: ${imageShadow};
        @media (max-width: 900px) {
            width: 90%;
            padding: 10px;
        }
    `,
};

export default Styles;
