import React from "react";
import Styles from "./styles";
import useDocManImgQuery from "./query";

const { Area, LandingImage } = Styles;

const LandingImg = () => {
  const gatsbyImageData = useDocManImgQuery();

  console.log(gatsbyImageData);
  return (
    <Area>
      <LandingImage
        image={gatsbyImageData}
        alt="table"
        style={{ height: "100%", width: "100%" }}
        imgStyle={{ objectFit: "contain" }}
      />
    </Area>
  );
};

export default LandingImg;
