import { v4 as uuidv4 } from "uuid";
import VectorImages from "../../../images/vector";

const { uiVec5, uiVec6, uiVec7, uiVec8 } = VectorImages;

const timeManagementCardsData = [
    {
        id: uuidv4(),
        image: uiVec5,
        title: "Raporty czasu pracy",
        text: "Kompleksowy system raportowania czasu pracy",
    },
    {
        id: uuidv4(),
        image: uiVec6,
        title: "Zarządzanie urlopami",
        text: "Możliwość zarządzania wnioskami o urlop",
    },
    {
        id: uuidv4(),
        image: uiVec7,
        title: "Kalendarz",
        text: "Kalendarz czasu pracy i przypisania do projektów",
    },
    {
        id: uuidv4(),
        image: uiVec8,
        title: "Raporty",
        text: "Dostęp do raportów, wykresów dla pracowników i projektów",
    },
];

export { timeManagementCardsData };
