import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { myTheme } from "../../../MyTheme";

const { imageShadow } = myTheme;

const Styles = {
    Area: styled.div`
        grid-area: landingNavBtns;
        padding: 15px;
        display: flex;
        margin-top:-150px;
        @media (max-width: 900px) {
            margin-top: 0;
            justify-content: right;
        }
    `,
    MobileButton: styled.button`
        background: none;
        border: none;
        display: none;
        @media (max-width: 900px) {
            display: inline;
            align-item: right;
        }
    `,
    Background: styled.div`
        position: absolute;
        height: calc(155vh + 5000px);
        width: 100%;
        top: 0;
        left: 0;
        z-index: -10;
        background: linear-gradient(180deg, rgba(205, 211, 244, 0.4) 0%, rgba(198, 199, 205, 0) 12.15%, rgba(204, 210, 239, 0.2) 23.09%, rgba(198, 199, 205, 0.00844276) 32.99%, rgba(203, 207, 231, 0.2) 57.99%, rgba(198, 199, 204, 0) 100%);
    `
};

export default Styles;
