import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { myTheme } from "../../../MyTheme";

const { imageShadow } = myTheme;

const Styles = {
    Area: styled.div`
        grid-area: hrImg;
        padding: 15px;
        margin-bottom: 120px;
    `,
    LandingImage: styled(GatsbyImage)`
        width: 30%;
        z-index: +1;
        position: absolute;
        margin-left: 120px;
        margin-top: 120px;
        filter: ${imageShadow}; 
        @media (max-width: 900px) {
            width: 60%;
            margin-left: 0;
            margin-top: 80px;
        }
    `,
};

export default Styles;
