import React from "react";
import LandingCenterImg from "./LandingCenterImg";
import LandingDesc from "./LandingDesc";
import LandingImg from "./LandingImg";
import LandingLogo from "./LandingLogo";
import LandingNavBtns from "./LandingNavigationBtns";

const Landing = () => {
    return (
        <>
            <LandingLogo/>
            <LandingNavBtns/>
            <LandingDesc />
            <LandingImg />
            <LandingCenterImg/>
        </>
    );
};

export default Landing;
