import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { myTheme } from "../../../MyTheme";

const { imageShadow } = myTheme;

const Styles = {
    Area: styled.div`
        grid-area: hrImg;
        padding: 10px;
        margin-bottom: 120px;
    `,
    LandingImage: styled(GatsbyImage)`
        width: 30%;
        z-index: -1;
        position: absolute;
        right: 3%;
        filter: ${imageShadow};
        @media (max-width: 900px) {
            width: 60%;
            margin-bottom: 20px;
        }  
    `,
};

export default Styles;
