import React from "react";
import Styles from "./styles";
import H2Title from "../../../components/H2Title";
import PText from "../../../components/PText";

const { Area } = Styles;

const LandingAddDesc = () => {
  return (
    <Area>
      <H2Title textAlign={"left"}>
        Zwiększ efektywność i monitoruj wydajność
      </H2Title>
      <PText>
        Jest to komplementarne narzędzie zastępujące skoroszyty i narzędzia
        dedykowane do tylko jednego zadania <br /><br/> Jesteśmy zespołem
        przedsiębiorców z umiejętnościami w zakresie przedsiębiorczości,
        projektowania i marketingu. Wierzymy, że czas i uwaga to najcenniejsze
        zasoby, dlatego stworzyliśmy Ninja, aby pomóc nam śledzić nasz czas i
        być bardziej wydajnym. Pracujemy również nad przydatną platformą
        produktywności z integracjami, które ułatwią Ci przejęcie kontroli nad
        swoim dniem. <br/><br/> — <br/><br/> Ninja to osobisty asystent dla każdego, kto prowadzi
        własną firmę i potrzebuje zarządzać projektami, zadaniami i czasem.
      </PText>
    </Area>
  );
};

export default LandingAddDesc;
