import styled from "styled-components";

const Styles = {
    PageGrid: styled.div`
        display: grid;
        grid-template-columns: 7% 13% 10% 10% 10% 10% 10% 10% 13% 7%;
        grid-template-rows: 35vh 120vh 500px 300px 400px 200px 700px 700px 700px 700px 200px 600px 500px;
        gap: 0px 0px;
        width: 100%;
        grid-template-areas:
            ". landingLogo landingLogo . landingNavBtns landingNavBtns . . . ."
            ". landingDesc landingDesc landingDesc landingImg landingImg landingImg landingImg landingImg ."
            ". landingAddImg landingAddImg landingAddImg landingAddImg landingAddDesc landingAddDesc landingAddDesc landingAddDesc ."
            ". timeManagementTitle timeManagementTitle timeManagementTitle timeManagementTitle timeManagementTitle timeManagementTitle timeManagementTitle timeManagementTitle ."
            ". timeManagementGrid timeManagementGrid timeManagementGrid timeManagementGrid timeManagementGrid timeManagementGrid timeManagementGrid timeManagementGrid ."
            ". docsManagementTitle docsManagementTitle docsManagementTitle docsManagementTitle docsManagementTitle docsManagementTitle docsManagementTitle docsManagementTitle ."
            ". docsManagementDesc docsManagementDesc docsManagementDesc docsManagementDesc docsManagementImg docsManagementImg docsManagementImg docsManagementImg ."
            ". safeMoneyImg safeMoneyImg safeMoneyImg safeMoneyImg safeMoneyDesc safeMoneyDesc safeMoneyDesc safeMoneyDesc ."
            ". hrDesc hrDesc hrDesc hrImg hrImg hrImg hrImg hrImg ."
            ". biImg biImg biImg biImg biImg biDesc biDesc biDesc ."
            ". contactTitle contactTitle contactTitle contactTitle contactTitle contactTitle contactTitle contactTitle ."
            ". contactForm contactForm contactForm contactForm contactForm contactForm contactForm contactForm ."
            "footer footer footer footer footer footer footer footer footer footer";
        @media (max-width: 900px) {
            grid-template-columns: 5% 45% 45% 5%;
            grid-template-rows: 10vh 750px 300px 800px 450px 20vh 800px 35vh 400px 500px 300px 500px 350px 500px 300px 400px 10vh 800px 800px;
            grid-template-areas:
                ". landingLogo landingNavBtns ."
                ". landingDesc landingDesc ."
                ". landingImg landingImg ."
                ". landingAddDesc landingAddDesc ."
                ". landingAddImg landingAddImg ."
                ". timeManagementTitle timeManagementTitle ."
                ". timeManagementGrid timeManagementGrid ."
                ". docsManagementTitle docsManagementTitle ."
                ". docsManagementImg docsManagementImg ."
                ". docsManagementDesc docsManagementDesc ."
                ". safeMoneyImg safeMoneyImg ."
                ". safeMoneyDesc safeMoneyDesc ."
                ". hrImg hrImg ."
                ". hrDesc hrDesc ."
                ". biImg biImg ."
                ". biDesc biDesc ."
                ". contactTitle contactTitle ."
                ". contactForm contactForm ."
                "footer footer footer footer";
        }
    `,
};

export default Styles;
