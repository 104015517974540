import { graphql, useStaticQuery } from "gatsby";

const useQuery = () => {
    const landingQuery = useStaticQuery(graphql`
        {
            allFile(
                filter: { name: { in: ["godzinnaMainPage", "calculator"] } }
                sort: { fields: name }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality:100)
                        }
                    }
                }
            }
        }
    `);

    return landingQuery;
};

const useLandingQuery = () => {
    const {
        allFile: {
            edges: [firstElement, secondElement],
        },
    } = useQuery();

    return { firstElement, secondElement };
};

export default useLandingQuery;
