import React from "react";
import DocManagementImg from "./DocManagementImg";
import Grid from "./Grid";
import Title from "./Title";

const DocManagement = () => {
    return (
        <>
            <Title />
            <Grid />
            <DocManagementImg/>
        </>
    );
};

export default DocManagement;