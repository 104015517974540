import styled from "styled-components";

const Styles = {
    Area: styled.div`
        grid-area: footer;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background-color: #ECEEFA;
        margin-top: 100px;
        @media (max-width: 900px) {
            flex-direction: column;
        }
    `,
};

export default Styles;
