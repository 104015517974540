import styled from "styled-components";
import { myTheme } from "../../MyTheme";
import { IH1 } from "./types";

const {
    fontSize: { gigantic, huge },
    color: { black },
} = myTheme;

const Styles = {
    H1: styled.h1<IH1>`
        font-size: ${gigantic};
        color: ${black};
        text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
        @media (max-width: 900px) {
            font-size: ${huge};
        }
    `,
};

export default Styles;
