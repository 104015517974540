import React from "react";
import Styles from "./styles";
import H1Title from "../../../components/H1Title";
import PText from "../../../components/PText";
import Button from "../../../components/Button";
import { myTheme } from "../../../MyTheme";
import LandingProfitsImg from "../LandingProfitsImg";
import { featurePillLandingData } from "../LandingProfitsImg/data";
import FeaturePill from "../../../components/FeaturePill";
import { GatsbyImage } from "gatsby-plugin-image";
import useLandingProfitsImgQuery from "../LandingProfitsImg/query";

const { Area, DoubleGridColumn, AreaGrid } = Styles;
const {
    color: { blue },
    fontWeight: { thick },
} = myTheme;

const LandingDesc = () => {
    const gatsbyImageData = useLandingProfitsImgQuery();
    return (
        <Area>
            <PText color={blue} weight={thick}>
                {"CZAS PRACY & FINANSE & HR & OBIEG DOKUMENTÓW"}
            </PText>
            <H1Title textAlign="left">Ninja zrobi to za Ciebie</H1Title>
            <PText>
                Oak Ninja jest dedykowanym i spersonalizowanym produktem
                dopasowanym do zarządzania Twoją organizacją.
            </PText>
            <Button>Zamów demo produktu</Button>
            <DoubleGridColumn>
                <AreaGrid>
                    {featurePillLandingData.map(({ id, icon, desc }) => (
                        <FeaturePill key={id} props={{ icon }} smaller={false}>
                            {desc}
                        </FeaturePill>
                    ))}
                </AreaGrid>

            </DoubleGridColumn>
            {/* <GatsbyImage
                    style={{
                        position: "absolute",
                        top: "50px",
                        right: "-20%",
                        maxWidth: "60%",
                        maxHeight: "60%",
                    }}
                    imgStyle={{ position: "absolute" }}
                    image={gatsbyImageData}
                    alt="income increase"
                /> */}
        </Area>
    );
};

export default LandingDesc;
