import React, { useState } from "react";
import Styles from "./styles";
import PText from "../../../components/PText";
import NavigationBtnProduct from "../../../components/NavigationBtnProduct";
import NavigationBtnContact from "../../../components/NavigationBtnContact";
import { StaticImage } from "gatsby-plugin-image";
import MobileMenu from "../MobileMenu";

const { Area, MobileButton, Background } = Styles;

const LandingNavBtns = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const ToggleMenu = () => {
    setIsMenuActive((prev) => !prev);
  };

  return (
    <Area>
      <Background/>
      <MobileButton onClick={ToggleMenu}>
        {isMenuActive === false ? (
          <StaticImage
            src={"../../../images/vector/mobileMenu.svg"}
            placeholder="blurred"
            style={{ width: "100%", height: "100%" }}
            imgStyle={{ objectFit: "contain" }}
            alt="Mobile menu"
            quality={100}
          />
        ) : (
          <StaticImage
            src={"../../../images/vector/mobileX.svg"}
            placeholder="blurred"
            style={{ width: "100%", height: "100%" }}
            imgStyle={{ objectFit: "contain" }}
            alt="Mobile menu x"
            quality={100}
          />
        )}
      </MobileButton>
      <MobileMenu isMenuActive={isMenuActive}/>

      <NavigationBtnProduct mobile={false}>O produkcie</NavigationBtnProduct>
      <NavigationBtnContact mobile={false}>Kontakt</NavigationBtnContact>
    </Area>
  );
};

export default LandingNavBtns;
