import React from "react";
import Styles from "./styles";
import useLandingAddImageQuery from "./query";

const { Area, NinjaImage, CalculatorImage } = Styles;

const LandingAddImage = () => {
    const { firstElement, secondElement } = useLandingAddImageQuery();

    return (
        <Area>
            <NinjaImage
                image={secondElement.node.childImageSharp.gatsbyImageData}
                alt="landing video"
            />
            <CalculatorImage
                image={firstElement.node.childImageSharp.gatsbyImageData}
                alt="landing video"
            />
        </Area>
    );
};

export default LandingAddImage;
