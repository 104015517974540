import styled from "styled-components";
import { myTheme } from "../../MyTheme";
import { IH2 } from "./types";

const {
    fontSize: { large, big },
    color: { black },
} = myTheme;

const Styles = {
    H2: styled.h2<IH2>`
        font-size: ${large};
        border-bottom: 1px solid;
        color: ${black};
        text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
        margin: 0;
        @media (max-width: 900px) {
            font-size: ${big};
            border-bottom: none;
        }
    `,
};

export default Styles;
