import styled from "styled-components";

const Styles = {
  Area: styled.div`
        grid-area: timeManagementGrid;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-auto-columns: 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
            ". ."
            ". .";
        @media (max-width: 900px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            gap: 0px 0px;
            grid-auto-flow: column;
            grid-template-areas:
                "."
                "."
                "."
                ".";
        }
    `,
};

export default Styles;
