import styled from "styled-components";
import { myTheme } from "../../MyTheme";
import { ISize } from "./types";

const {
    color: { black },
    fontSize: { tiny },
    fontWeight: { thick },
} = myTheme;

const Styles = {
    Pill: styled.div<ISize>`
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 139px;
        height: 38px;
        background-color: #eceefa;
        color: ${black};
        border-radius: 8px;
        padding: 6px;
        font-size: ${tiny};
        font-family: "Poppins", "sans-serif";
        z-index: +1;
        margin-right: 6px;
        @media(max-width: 900px){
            width: ${({smaller}) => (smaller ? "30vw" : "139px")};
            margin-right: ${({smaller}) => (smaller ? "0" : "6px")};
        }
    `,
    Amount: styled.div`
        font-family: "Poppins", "sans-serif";
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: ${thick};
    `,
    Icon: styled.img`
        margin-right: 6px;
        width: 20px;
        align-self: flex-start;
    `,
    Desc: styled.div<ISize>`
        margin-top: 3px;
        width: 120px;
        text-align: left;
        align-self: top;
        @media(max-width: 900px){
            width: ${({smaller}) => (smaller ? "30vw" : "120px")};
        }
    `,
};

export default Styles;
