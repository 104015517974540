import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import { IH1 } from "./types";

const { H1 } = Styles;

const H1Title = ({ textAlign, children }: PropsWithChildren<IH1>) => {
    return <H1 textAlign={textAlign}>{children}</H1>;
};

export default H1Title;
