import styled from "styled-components";

const Styles = {
    Wrapper: styled.div`
        width: 530px;
        height: 175px;
        background-color: rgba(236,238,250,0.4);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 8px; 
        color: black;
        @media (max-width: 900px) {
            width: 100%;
        }
    `,
    CardImg: styled.img`
        padding: 20px;
    `,
    DescContainer: styled.div`
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 20px;
    `,
};

export default Styles;
