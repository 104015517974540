import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import { IP } from "./types";

const { P } = Styles;

const PText = ({ color, weight, small, children }: PropsWithChildren<IP>) => {
    return <P color={color} weight={weight} small={small}>{children}</P>;
};

export default PText;
