import styled from "styled-components";
import { myTheme } from "../../MyTheme";
import { IH2 } from "./types";

const {
    fontSize: { huge, large },
    color: { black },
} = myTheme;

const Styles = {
    H2: styled.h2<IH2>`
        font-size: ${huge};
        color: ${black};
        text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
        margin: 0;
        @media (max-width: 900px) {
            font-size: ${large};
            text-align: left;
        }
    `,
};

export default Styles;
