import styled from "styled-components";

const Styles = {
    Area: styled.div`
        margin-top: 200px;
        grid-area: timeManagementTitle;
        @media (max-width: 900px) {
            margin-top: 0;
        }
    `,
};

export default Styles;
