import React from "react";
import useScalesImgQuery from "./query";
import Styles from "./styles";


const { Area, LandingImage } = Styles;

const LandingImg = () => {
    const gatsbyImageData = useScalesImgQuery();

    console.log(gatsbyImageData);
    return (
        <Area>
            <LandingImage image={gatsbyImageData} alt="scales" />
        </Area>
    );
};

export default LandingImg;