import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import { IH2 } from "./types";

const { H2 } = Styles;

const H2Title = ({ textAlign, children }: PropsWithChildren<IH2>) => {
    return <H2 textAlign={textAlign}>{children}</H2>;
};

export default H2Title;
