import styled from "styled-components";


const Styles = {
    Area: styled.div`
        grid-area: landingDesc;
        padding: 15px;
    `,
    DoubleGridColumn: styled.div`
        /* grid-area: landingDesc; */
        position: relative;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 200px;
        gap: 0px 0px;
        width: 100%;
        grid-template-areas: ". .";
        /* top: 55%; */
        /* border: 2px solid black; */
    `,

        AreaGrid: styled.div`
        width: 100%;
        position: relative;
        padding-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-auto-columns: 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        `, 
    

};

export default Styles;
