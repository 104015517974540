import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import VectorImages from "../../images/vector";
import { Mobile } from "./types";

// const { arrow } = VectorImages;

const { Btn } = Styles;

const NavigationBtns = ({ children, mobile }: PropsWithChildren<Mobile>) => {
    const handleClick = () => {}
    return (
        <Btn to='/pl/#contactUs' mobile={mobile}>
            {children}
        </Btn>
    );
};

export default NavigationBtns;
