import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { myTheme } from "../../../MyTheme";

const { imageShadow } = myTheme;

const Styles = {
    Area: styled.div`
        grid-area: landingLogo;
        padding: 20px;
    `,
    LandingImage: styled(GatsbyImage)`
        width: 60%;
        filter: ${imageShadow};
        @media (max-width: 900px) {
            width: 70%;
        }
    `,
};

export default Styles;
