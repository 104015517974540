import { graphql, useStaticQuery } from "gatsby";

const useQuery = () => {
    const landingQuery = useStaticQuery(graphql`
        {
            allFile(filter: { name: { eq: "logoOakNinja" } }) {
                edges {
                    node {
                        id
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    `);

    return landingQuery;
};

const useLandingLogoQuery = () => {
    const {
        allFile: {
            edges: [firstElement],
        },
    } = useQuery();
    const {
        node: {
            childImageSharp: { gatsbyImageData },
        },
    } = firstElement;

    return gatsbyImageData;
};

export default useLandingLogoQuery;
