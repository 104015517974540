import styled from "styled-components";

const Styles = {
    Wrapper: styled.div`
        /* width: 400px; */
        height: 41px;
        display: flex;
        align-content: flex-start;
        flex-direction: row;
        /* justify-content: center; */
        align-items: center;
        color: black;
        z-index: -1;
    `,
    CardImg: styled.img`
        padding: 10px;
        height: 40px;
    `,
    DescContainer: styled.div`
        display: flex;
        /* width: 400px; */
        flex-direction: column;
        align-items: left;
        padding: 20px;
    `,
};

export default Styles;
