import React from "react";
import Styles from "./styles";
import useLandingLogoQuery from "./query";
import { StaticImage } from "gatsby-plugin-image";

const { Area, LandingImage } = Styles;

const LandingLogo = () => {
    const gatsbyImageData = useLandingLogoQuery();

    return (
        <Area>
            <LandingImage image={gatsbyImageData} alt="landing logo" />
            {/* <StaticImage src="../../../images/raster/logo.png" alt="logo"/> */}
        </Area>
    );
};

export default LandingLogo;
