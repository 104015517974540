import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import GlobalStyles from "../../GlobalStyles";

const { Wrapper } = Styles;

const Layout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Wrapper>
      <GlobalStyles />
      {children}
    </Wrapper>
  );
};

export default Layout;
