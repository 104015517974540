import React from "react";
import BIimage from "./BIimage";
import Title from "./Title";

const BI = () => {
    return (
        <>
            <Title />
            <BIimage/>
        </>
    );
};

export default BI;