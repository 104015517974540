import { v4 as uuidv4 } from "uuid";
import VectorImages from "../../../images/vector";

const { docMan1, docMan2, docMan3, docMan4 } = VectorImages;

const docManagementCardsData = [
    {
        id: uuidv4(),
        image: docMan1,
        title: "Wszystkie dokumenty w jednym miejscu",
        indent: "0px",
    },
    {
        id: uuidv4(),
        image: docMan2,
        title: "Dodawaj komentarze do kontraktów",
        indent: "40px",
    },
    {
        id: uuidv4(),
        image: docMan3,
        title: "Zarządzaj obiegiem umów i zamówień",
        indent: "80px",
    },
    {
        id: uuidv4(),
        image: docMan4,
        title: "Monitoruj kontrakty i zamówienia ",
        indent: "120px",
    },
];

export { docManagementCardsData as docManagementCardsData };
