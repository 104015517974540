import { v4 as uuidv4 } from "uuid";
import VectorImages from "../../../images/vector";

const { uiVecCol1, uiVecCol2, uiVecCol3, ebook } = VectorImages;

const featurePillLandingData = [
    {
        id: uuidv4(),
        icon: uiVecCol1,
        desc: "Zarządzanie kadrami",
    },
    {
        id: uuidv4(),
        icon: uiVecCol2,
        desc: "Finanse",
    },
    {
        id: uuidv4(),
        icon: uiVecCol3,
        desc: "Czas pracy",
    },
    {
        id: uuidv4(),
        icon: ebook,
        desc: "Obieg dokumentów",
    },
];

const featurePillFinanceData = [
    {
        id: uuidv4(),
        icon: uiVecCol3,
        desc: "Zarządzanie kadrami",
    },
    {
        id: uuidv4(),
        icon: uiVecCol1,
        desc: "Czas pracy",
    },
    {
        id: uuidv4(),
        icon: uiVecCol2,
        desc: "Finanse",
    },
];

export { featurePillLandingData, featurePillFinanceData };
