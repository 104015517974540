import React from "react";
import Styles from "./styles";
import H2Title from "../../../components/H2Title";
import PText from "../../../components/PText";
import scalesImg  from "../ScalesImg";
import ScalesImg from "../ScalesImg";

const { Area} = Styles;

const Title = () => {
    return (
        <Area>
            <H2Title textAlign={"left"}>Wbudowany BI</H2Title>
            <br/>
            <PText>
            System w przejrzysty sposób prezentuje wykresy zarówno finansowe jak i przypisań do projektów czy kalendarza dostępności.
            </PText>
            <br/>
            <PText>
            Każdy projekt jak i pracownik może być monitorowany oddzielnie w wbudowanym systemie do statystyk
            </PText>
            <br/>
            <PText>
            Możliwość integracji z zewnętrzymi dashboardami Bussines Inteligence jak Kibana, Qlik, Amazon QuickSight itd  
            </PText>
            <ScalesImg/>
        </Area>
    );
};

export default Title;
