import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import FooterTitle from "../../../components/FooterTitle";
import PText from "../../../components/PText";
import { IP } from "./types";

const { Area } = Styles;

const FooterCard = ({props: {title, desc1, desc2},}: PropsWithChildren<IP>) => {
    return (
        <Area>
            <FooterTitle>{title}</FooterTitle>
            <PText>{desc1}</PText>
            <PText>{desc2}</PText>
        </Area>
    );
};

export default FooterCard;
