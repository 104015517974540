import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import { IPill } from "./types";

const { Pill, Amount, Icon, Desc } = Styles;

const FeaturePill = ({
    children,
    props: { icon },
    smaller,
}: PropsWithChildren<IPill>) => {
    return (
        <Pill smaller={smaller}>
            <Icon src={icon} alt="increase amount" />
            <Desc smaller={smaller}> {children}</Desc>
        </Pill>
    );
};

export default FeaturePill;
