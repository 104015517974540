import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const Styles = {
    Area: styled.div`
        grid-area: safeMoneyImg;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 120px;
        @media (max-width: 900px) {
            margin-bottom: 20px;
            padding: 0;
        }
    `,
    LandingImage: styled(GatsbyImage)`
        width: 40%;
        z-index: -1;
    `,
    AbsoluteArea: styled.div`
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 40%;
        & > :nth-child(1){
            margin-top: 5%
        }
        & > :nth-child(2){
            margin-top: -30%;
        }
        & > :nth-child(3){
            margin-top: 10%;
        }
        @media (max-width: 900px) {
            width: 70%;
            justify-content: center;
            & > :nth-child(1){
                margin-top: 35%
            }
        }
    `
};

export default Styles;
