import styled from "styled-components";
import { Link } from "react-scroll";
import { Mobile } from "./types";

const Styles = {
    Wrapper: styled.div<Mobile>`
        display: ${({ isMenuActive }) => (isMenuActive ? "block" : "none")};
        position: absolute;
        width: 45vw;
        height: 20vh;
        background: #F8F9FF;
        top: 10vh;
        right: 0;
        text-align: center;
        padding-top: 5vh;
    `,
};

export default Styles;
