import styled from "styled-components";

const Styles = {
    Area: styled.div`
        grid-area: contactForm;
        display: inline-block;
    `,
    Row: styled.div`
        display: table;
        @media (max-width: 900px) {
            display: block;
        }
    `,
    Column: styled.div`
        display: table-cell;
        @media (max-width: 900px) {
            display: block;
        }
    `,
    TextArea: styled.textarea`
        font-size: 16px;
        width: 100%;
        border: none;
        height: 180px;
        padding: 10px 1px 10px 1em;
        resize: none;
        background-color: rgba(236,238,250,0.4);
        font-family: "Source, Sans Pro";
        border-radius: 8px; 
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #212529;
            opacity: 0.5;
        }
        `,
    Form: styled.form`
        display: flex;
        flex-direction: column;
    `,
    Label: styled.label`
        font-size: 12px;
        font-family: 'Source Sans Pro';
        display: block;
    `,
    Error: styled.p`
        font-size: 12px;
        margin-bottom: 10px;
        color: #d20808;
        font-family: 'Source Sans Pro';
        font-size: 0.8em;
        margin-top: 0.7em;
  `,
    Input: styled.input`
        font-size: 16px;
        width: 90%;
        border: none;
        background-color: rgba(236,238,250,0.4);
        font-family: "Source, Sans Pro";
        border-radius: 6px; 
        padding: 1em;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #212529;
            opacity: 0.5;
        }
    `,
};

export default Styles;