import styled from "styled-components";

const Styles = {
    Area: styled.div`
        grid-area: hrDesc;
        position: relative;
        left:0px;
    `,
};

export default Styles;
