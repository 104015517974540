import styled from "styled-components";
import { myTheme } from "../../MyTheme";
import { IP } from "./types";

const {
    fontSize: { normal, tiny },
    fontWeight: { common },
    color: { black },
} = myTheme;

const Styles = {
    P: styled.p<IP>`
        font-size: ${({ small }) => (small ? tiny : normal)};
        font-weight: ${({ weight }) => (weight ? weight : common)};
        color: ${({ color }) => (color ? color : black)};
        font-family: "Poppins", "sans-serif";
        line-height: 24px;
    `,
};

export default Styles;
