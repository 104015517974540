import { v4 as uuidv4 } from "uuid";
import VectorImages from "../../../images/vector";

const { uiVec5, uiVec6, uiVec7, uiVec8 } = VectorImages;

const footerData = [
    {
        id: uuidv4(),
        title: "Office Poland",
        desc1: "Żmigrodzka 244 51-131 Wrocław, Poland",
        desc2: "+48 797 743 064 office@oakfusion.pl"
    },
    {
        id: uuidv4(),
        title: "Austria",
        desc1: "c/o Impact Hub Vienna Lindengasse 56/18-19 1070 Wien, Austria",
        desc2: "+43 69919064248 vienna@oakfusion.com"
    },
    {
        id: uuidv4(),
        title: "Nordic",
        desc1: "Anckargripsgatan 3 211 19-SE Malmö Sweden",
        desc2: "office@oakfusion.pl"
    },
];

export { footerData };
