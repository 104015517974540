import burger from "./burger.svg";
import checkmark from "./checkmark.svg";
import uiVec1 from "./uiVec1.svg";
import uiVec2 from "./uiVec2.svg";
import uiVec3 from "./uiVec3.svg";
import uiVec4 from "./uiVec4.svg";
import uiVec5 from "./uiVec5.svg";
import uiVec6 from "./uiVec6.svg";
import uiVec7 from "./uiVec7.svg";
import uiVec8 from "./uiVec8.svg";
import uiVecCol1 from "./uiVecCol1.svg";
import uiVecCol2 from "./uiVecCol2.svg";
import uiVecCol3 from "./uiVecCol3.svg";
import arrow from "./arrow.svg";
import docMan1 from "./docMan1.svg";
import docMan2 from "./docMan2.svg";
import docMan3 from "./docMan3.svg";
import docMan4 from "./docMan4.svg";
import financeTick from "./checkmarkFinance.svg";
import ebook from "./ebook.svg";

const VectorImages = {
    burger,
    checkmark,
    uiVec1,
    uiVec2,
    uiVec3,
    uiVec4,
    uiVec5,
    uiVec6,
    uiVec7,
    uiVec8,
    uiVecCol1,
    uiVecCol2,
    uiVecCol3,
    arrow,
    docMan1,
    docMan2,
    docMan3,
    docMan4,
    financeTick,
    ebook
};

export default VectorImages;
