import React from "react";
import StaffManagementCalendarImg from "./StaffManagementCalendarImg";
import StaffManagementImg from "./StaffManagementImg";
import Title from "./Title";

const StaffManagement = () => {
    return (
        <>
            <Title />
            <StaffManagementCalendarImg/>
            <StaffManagementImg/>
        </>
    );
};

export default StaffManagement;