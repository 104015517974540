import React from "react";
import Layout from "../domain/Layout";
import IndexPage from "../domain/IndexPage";
import { Helmet } from "react-helmet";

const Index = () => (
  <Layout>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
        <meta charSet="utf-8"/>
        <title>OakNinja</title>
        <meta
          name="description"
          content="OakNinja - dedykowany i spersonalizowany produkt dopasowany do zarządzania Twoją organizacją."
        />
      </Helmet>
    <IndexPage />
  </Layout>
);

export default Index;
