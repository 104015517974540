import styled from "styled-components";

const Styles = {
    Area: styled.div`
        grid-area: docsManagementDesc;
        position: relative;
        display: grid;
        margin-top: 50px;
        grid-template-columns: 1fr;
        grid-template-rows: 100px 100px 100px 100px;
        grid-auto-columns: 1fr;
        grid-row-gap: 30px;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
            "."
            "."
            "."
            ".";
    `,
};

export default Styles;
