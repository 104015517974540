import React from "react";
import { docManagementCardsData } from "./data";
import Styles from "./styles";
import Card from "../Card";

const { Area } = Styles;

const Grid = () => {
    return (
        <Area>
            {docManagementCardsData.map(({ id, title, image, indent}) => (
                <Card key={id} props={{ id, image, title, indent}}>
                </Card>
            ))}
        </Area>
    );
};

export default Grid;