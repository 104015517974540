import styled from "styled-components";
import { IWrapper } from "./types";

const Styles = {
    Wrapper: styled.div<IWrapper>`
        width: 400px;
        height: 41px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: black;
        margin-left: ${props=>props.indent};
        @media (max-width: 900px) {
            width: 70%;
        }
    `,
    CardImg: styled.img`
        padding: 10px;
        height: 40px;
    `,
    DescContainer: styled.div`
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 20px;
        @media (max-width: 900px) {
            padding: 0;
        }
    `,
};

export default Styles;
