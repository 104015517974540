import styled from "styled-components";

const Styles = {
    Area: styled.div`
        grid-area: landingAddDesc;
        position: relative;
        z-index: 55;
        display: flex;
        flex-direction: column;
        padding-left: 130px;
        padding-top: 0px;
        @media (max-width: 900px) {
            padding-left: 0;
            justify-content: center;
            align-content: center;
        }
    `,
};

export default Styles;
