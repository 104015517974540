import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { myTheme } from "../../../MyTheme";

const { imageShadow } = myTheme;

const Styles = {
    Area: styled.div`
        grid-area: timeManagementGrid;
        padding: 15px;
        display: flex;
        @media (max-width: 900px) {
            padding: 0;
        }
    `,
    LandingImage: styled(GatsbyImage)`
        width: 35%;
        /* filter: ${imageShadow}; */
        left: 30%;
        z-index: -1;
        @media (max-width: 900px) {
            width: 30%;
            height: 10%;
            top: -12%;
            left: 70%;
            object-fit: scale-down;
        }
    `,
};

export default Styles;
