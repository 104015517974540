import styled from "styled-components";

const Styles = {
    Area: styled.div`
        margin-top: 120px;
        grid-area: docsManagementTitle;
        left:0px;
    `,
};

export default Styles;
