import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Poppins&family=Source+Sans+Pro:wght@400;600;700&display=swap');

    html {
scroll-behavior: smooth;
    }
    body{
        font-family: 'Source Sans Pro', "sans-serif";
        margin: 0px;
        padding: 0px;
     // color: #FFE8D1;
     // font-display: swap;
        font-size: 16px;
    }
`;

export default GlobalStyles;
