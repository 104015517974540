import styled from "styled-components";

const Styles = {
    Area: styled.div`
    @media (max-width: 900px) {
        text-align: center;
    }
    `,
};

export default Styles;
