import React from "react";
import LandingAddDesc from "./LandingAddDesc";
import LandingAddImage from "./LandingAddImage";

const LandingBottom = () => {
    return (
        <>
            <LandingAddDesc />
            <LandingAddImage />
        </>
    );
};

export default LandingBottom;
