import React from "react";
import Styles from "./styles";
import Landing from "../Landing";
import LandingBottom from "../LandingBottom";
import TimeManagement from "../TimeManagement";
import DocManagement from "../DocManagement";
import FinanceManagement from "../FinanceManagement";
import StaffManagement from "../StaffManagement";
import BI from "../BI";
import ContactUs from "../ContactUs";
import Footer from "../Footer";

const {
    PageGrid,
} = Styles;

const IndexPage = () => {
    return (
        <PageGrid>
            <Landing />
            <LandingBottom />
            <TimeManagement />
            <DocManagement />
            <FinanceManagement/>
            <StaffManagement />
            <BI/>
            <ContactUs/>
            <Footer/>
        </PageGrid>
    );
};

export default IndexPage;
