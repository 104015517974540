import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { myTheme } from "../../../MyTheme";

const { imageShadow } = myTheme;

const Styles = {
    Area: styled.div`
        grid-area: landingDesc;
        position: relative;
        z-index: -10;

    `,
    LandingImage: styled(GatsbyImage)`
        width: 70%;
        z-index: -10;
        position: absolute; 
        right: -25%; 
        bottom: 15%;
        @media (max-width: 900px) {
            bottom: 0;
            right: 0;
        }
    `,
};

export default Styles;
