import styled from "styled-components";
import { myTheme } from "../../MyTheme";
import { Link } from "gatsby";

const {
    color: { black },
    fontSize: { normal },
} = myTheme;

const Styles = {
    Btn: styled(Link)`
        background-color: ${black};
        color: white;
        border-radius: 6px;
        padding: 20px;
        font-size: ${normal};
        font-family: "Poppins", "sans-serif";
        outline: none;
        border: none;
        transition: 0.3s;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 200px;
        text-decoration: none;
        &:hover {
            cursor: pointer;
            background-color: darkgray;
        }
    `,
    Arrow: styled.img`
        margin-left: 15px;
        color: white;
    `,
};

export default Styles;
