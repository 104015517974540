import React from "react";
import Styles from "./styles";
import useLandingQuery from "./query";

const { Area, LandingImage } = Styles;

const LandingCenterImg = () => {
    const gatsbyImageData = useLandingQuery();

    console.log(gatsbyImageData);
    return (
        <Area>
            <LandingImage image={gatsbyImageData} alt="center img" />
        </Area>
    );
};

export default LandingCenterImg;
