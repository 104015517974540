import React from "react";
import Title from "./Title";
import Grid from "./Grid";
import ComputerImg from "./ComputerImg";

const TimeManagement = () => {
    return (
        <>
            <Title />
            <Grid />
            <ComputerImg/>
        </>
    );
};

export default TimeManagement;
