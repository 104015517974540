import React from "react";
import { useFormik } from "formik";
import Styles from "./style";
import Button from "../../components/Button";
import H2Title from "../../components/H2Title";

const { Area, TextArea, Label, Form, Input, Error, Row, Column } = Styles;

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Wymagane";
  }

  if (!values.email) {
    errors.email = "Wymagane";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Niepoprawny adres email";
  }

  if (!values.message) {
    errors.message = "Wymagane";
  } else if (values.message.length < 10) {
    errors.message = "Minimum 10 znaków";
  }
  return errors;
};

const ContactUs = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      {/* <Title /> */}
      <Area id='contactUs'>
      <H2Title textAlign={"center"}>Napisz do nas</H2Title>
      <br/> <br/> <br/>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Column>
              <Label>
                {formik.values.name ? "Imię" : String.fromCharCode(160)}
              </Label>
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="Imię"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <Error>
                {formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : String.fromCharCode(160)}
              </Error>
              <Label>
                {formik.values.companyName
                  ? "Nazwa firmy"
                  : String.fromCharCode(160)}
              </Label>
              <Input
                id="companyName"
                name="companyName"
                type="text"
                onChange={formik.handleChange}
                placeholder="Nazwa firmy [opcjonalnie]"
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
              />
              <Error>
                {formik.touched.companyName && formik.errors.companyName
                  ? formik.errors.companyName
                  : String.fromCharCode(160)}
              </Error>
            </Column>
            <Column>
              <Label>
                {formik.values.email ? "E-mail" : String.fromCharCode(160)}
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="E-mail"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <Error>
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : String.fromCharCode(160)}
              </Error>
              <Label>
                {formik.values.phoneNumber
                  ? "Telefon"
                  : String.fromCharCode(160)}
              </Label>
              <Input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                placeholder="Telefon [opcjonalnie]"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
              />
              <Error>{String.fromCharCode(160)}</Error>
            </Column>
          </Row>

          <Label>
            {formik.values.message
              ? "Twoja wiadomość"
              : String.fromCharCode(160)}
          </Label>
          <TextArea
            id="message"
            name="message"
            type="text"
            placeholder="Tutaj napisz wiadomość ..."
            maxlength="300"
            rows="4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          <Error>
            {formik.touched.message && formik.errors.message
              ? formik.errors.message
              : String.fromCharCode(160)}
          </Error>
          {/* <ButtonWrapper type="submit"> */}
            <Button type="submit">Skontaktuj się z nami</Button>
          {/* </ButtonWrapper> */}
        </Form>
      </Area>
    </>
  );
};

export default ContactUs;
