import React from "react";
import Styles from "./styles";
import useFinanceManImgQuery from "./query";
import FeaturePill from "../../../components/FeaturePill";
import { featurePillFinanceData } from "../../Landing/LandingProfitsImg/data";

const { Area, LandingImage, AbsoluteArea } = Styles;

const LandingImg = () => {
  const gatsbyImageData = useFinanceManImgQuery();

  console.log(gatsbyImageData);
  return (
    <Area>
      <LandingImage
        image={gatsbyImageData}
        alt="pig"
        style={{ height: "100%", width: "100%" }}
        imgStyle={{ objectFit: "contain" }}
      />
      <AbsoluteArea>
        {featurePillFinanceData.map(({ id, icon, desc }) => (
          <FeaturePill key={id} props={{ icon }} smaller={true}>
            {desc}
          </FeaturePill>
        ))}
      </AbsoluteArea>
    </Area>
  );
};

export default LandingImg;
