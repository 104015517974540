import styled from "styled-components";
import { myTheme } from "../../MyTheme";
import { Link } from "gatsby";
import { Mobile } from "./types";

const {
    color: { black },
    fontSize: { normal },
} = myTheme;

const Styles = {
    Btn: styled(Link)<Mobile>`
        color: ${black};
        padding: 10px;
        font-size: ${normal};
        font-family: "Poppins", "sans-serif";
        outline: none;
        border: none;
        transition: 0.3s;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: transparent;
        text-decoration: none;
        cursor: pointer;
        &:hover{
            color: #4F66C9;
        }
        @media (max-width: 900px) {
            display: ${({ mobile }) => (mobile ? "block" : "none")};
            margin-left: auto;
            margin-right: auto;
        }
    `,
};

export default Styles;
