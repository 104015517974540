import React from "react";
import FinanceManagementImg from "./FinanceManagementImg";
import Grid from "./Grid";
import Title from "./Title";

const FinanceManagement = () => {
    return (
        <>
            <Title />
            <Grid />
            <FinanceManagementImg/>
        </>
    );
};

export default FinanceManagement;