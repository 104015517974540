import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { myTheme } from "../../../MyTheme";

const { imageShadow } = myTheme;

const Styles = {
    Area: styled.div`
        grid-area: landingImg;
        padding-left: 60px;
        padding-top: 90px;
        z-index: 10;
        @media (max-width: 900px) {
            padding-left: 0;
            padding-top: 0;
        }
    `,
    LandingImage: styled(GatsbyImage)`
        width: 95%;
        filter: ${imageShadow};
        @media (max-width: 900px) {
            width: 100%;
        }
    `,
};

export default Styles;
